@font-face {
    font-family: 'Noto-Sans';
src: url("/../Content/Fonts/noto-sans/NotoSans-Regular.ttf") format("truetype");
}

body {
    font-family: 'Open-Sans', sans-serif !important;
}

.sidebar-enabled .content {
    visibility: visible;
}

.sidebar-enabled .sidebar-nav {
    width: 250px;
}

.k-state-selected {
    background-color: #86bc25;
    color: white;
}

.k-treeview-item {
    list-style: none;
}

:focus {
    outline: none;
}

.zindex {
    z-index: 99000 !important;
}

.with-border-bottom {
    border-bottom: 1px solid black;
    border-color: #ddd;
    padding-bottom: 25px;
    margin-bottom: 25px;
}


.btn-margins {
    margin-right: 5px;
}

.btn-deloitte{
    padding: 8px 16px 8px 16px;
    background: #0088CA;
    border: none;
    color: white !important;
    border-radius: 0% !important;
    text-align: center;
}

.ng-touched  .ng-invalid:not(form) {
    border: 1px solid #EC5352; /* red */
}


.form-control-feedback{
    display: none;
  }

#toast-container > div {
    opacity: 1;
}